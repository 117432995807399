import React from 'react';
function Home() {
    return (
        <div className="home">
          <p>
              Welcome to my site. 
            </p>
        </div>
    );
}

export default Home;
